.App {
  text-align: center;
}

.App-logo {
  height: 8vmin;
  pointer-events: none;
}

.App-header {
  background-color: #022c70;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

td {
  text-align: left;
}

.level_0 {
  font-weight: bold;
}

.level_1 td:first-child {
  display: block;
  margin-left: 10px;
}

.level_2 td:first-child {
  display: block;
  margin-left: 20px;
}

.level_3 td:first-child {
  display: block;
  margin-left: 30px;
}